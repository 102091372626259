import Web3 from "web3";
import BN from "bn.js";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers";
import { bsc, bscTestnet } from "viem/chains";

if (typeof process !== "undefined") {
  if (!process.nextTick)
    process.nextTick = (fn) => {
      setTimeout(fn, 1);
    };
}

export const useWeb3 = () =>
  useState("web3", () => {
    return { Web3, web3: null, modal: null, BN };
  });

export const useConnected = () => useState("connected", () => false);

export const useChangeNetwork = () => useState("changeNetwork", () => false);

export const useAddress = () => useState("address", () => "");

export const useTCmodal = () => useState("tcModal", () => false);

export const useCreator = () => useState("creator", () => false);

export const useUserKYC = () => useState("userKYC", () => false);
