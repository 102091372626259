import VueGtag from 'vue-gtag-next'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueGtag, {

    property: {
      id: 'G-9D4CKTHTV4',
      config: {
        anonymize_ip: true, // anonymize IP
        send_page_view: false, // might be necessary to avoid duplicated page track on page reload
      },
      debug: true, // enable to track in dev mode
      disableAutoPageTrack: false, // disable if you don't want to track each page route with router.afterEach(...).
    }
  })
})