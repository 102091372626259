let API_BASE;

async function post(endpoint, data) {
  const res = await fetch(API_BASE + endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return await res.json();
}

async function get(endpoint) {
  const res = await fetch(API_BASE + endpoint, { method: "GET" });
  return await res.json();
}

let currencyList = [];

async function addSignature(account, address, data) {
  try {
    data.timestamp = new Date().getTime();
    console.log(JSON.stringify(data));
    const signature = account.utils.stringToHex(JSON.stringify(data));
    console.log(signature);
    data.signature = await account.eth.personal.sign(signature, address, "798");
    return;
  } catch (error) {
    console.log("Signature error: ", error);
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  API_BASE =
    nuxtApp.$config.public.env == "development"
      ? "http://localhost:8080/"
      : "/api/";
  const web3 = useWeb3();
  const address = useAddress();
  currencyList.push(
    {
      address: nuxtApp.$config.public.busd,
      name: "BUSD",
    },
    {
      address: nuxtApp.$config.public.usdc,
      name: "USDC",
    },
    {
      address: nuxtApp.$config.public.usdt,
      name: "USDT",
    }
  );
  return {
    provide: {
      api: {
        currencyList,
        currencyName: (address) => {
          for (const currency of currencyList) {
            if (
              (!address && !currency.address) ||
              (address && currency.address == address.toLowerCase())
            )
              return currency.name;
          }
        },
        getUser: async (address) => {
          try {
            return await get(`user/${address}/`);
          } catch {
            return {};
          }
        },
        updateLogin: async (address) => {
          try {
            return await get(`user/login/?user=${address}`);
          } catch {
            return {};
          }
        },
        changeUser: async (data) => {
          try {
            await addSignature(web3.value.web3, address.value, data);
            return await post("user", data);
          } catch (e) {
            return { error: e };
          }
        },
        getNFT: async (id) => {
          try {
            const data = await get("search/nft/?id=" + id);
            if (data.success) return data.result[0];
          } catch {}
          return {};
        },
        getNFTs: async (params) => {
          let q = "";
          for (const k of Object.keys(params)) {
            q += k + "=" + params[k] + "&";
          }
          try {
            const data = await get("search/nft/?" + q);
            if (data.success) return data.result;
          } catch {}
          return [];
        },
        getCollections: async (params) => {
          let q = "";
          for (const k of Object.keys(params)) {
            q += k + "=" + params[k] + "&";
          }
          try {
            const data = await get("search/collection/?" + q);
            if (data.success) return data.result;
          } catch {}
          return [];
        },
        getUsers: async (params) => {
          let q = "";
          for (const k of Object.keys(params)) {
            q += k + "=" + params[k] + "&";
          }
          try {
            const data = await get("search/user/?" + q);
            if (data.success) return data.result;
          } catch {}
          return [];
        },
        getChallenges: async (params) => {
          let q = "";
          for (const k of Object.keys(params)) {
            q += k + "=" + params[k] + "&";
          }
          try {
            const data = await get("challenges/all/?" + q);
            if (data.success) return data;
          } catch {}
          return { challenges: [], completed: [] };
        },
        claimChallenge: async (payload) => {
          const d = {
            ids: payload.ids,
            collection: payload.collection,
          };
          try {
            await addSignature(web3.value.web3, address.value, d);
            const data = await post(`challenges/claim/`, d);
            console.log(data);
            return true;
          } catch (e) {
            console.log(e);
            return { error: e };
          }
        },
        acceptOffer: async (offer) => {
          const d = {};
          try {
            await addSignature(web3.value.web3, address.value, d);
            const data = await post(`nft/offer/${offer}/`, d);
            if (data.error == "please allow the contract to transfer your NFTs")
              return {
                error: "please allow the contract to transfer your NFTs",
              };
            return web3.value.nft.methods
              .trade(
                data.offer.buyerId,
                data.offer.currency,
                data.offer.price,
                data.offer.nftId,
                data.offer.nonce,
                data.timestamp,
                data.signature
              )
              .send({ from: address.value });
          } catch (e) {
            console.log(e);
            return { error: e };
          }
        },
        makeOffer: async (id, currency, price) => {
          console.log(id, currency, price);
          if (
            !currency ||
            !currencyList.some((c) => c.address == currency.toLowerCase()) ||
            isNaN(price)
          )
            return;
          const data = {
            currency,
            token: id,
            price: web3.value.web3.utils.toWei(price.toString(), "ether"),
          };
          try {
            await addSignature(web3.value.web3, address.value, data);
            return await post("nft/offer", data);
          } catch (e) {
            console.error("Error");
            console.error(e);
            return { error: e };
          }
        },
        sellNFT: async (id, currency, price) => {
          if (
            (currency &&
              !currencyList.some((c) => c.address == currency.toLowerCase())) ||
            isNaN(price)
          )
            return;
          const data = {
            currency,
            nft: id,
            price: web3.value.web3.utils.toWei(price.toString(), "ether"),
          };
          try {
            await addSignature(web3.value.web3, address.value, data);
            return await post("nft/sell", data);
          } catch (e) {
            return { error: e };
          }
        },
        cancelSale: (nonce) => {
          return web3.value.nft.methods
            .cancelTradeNonce(nonce)
            .send({ from: address.value });
        },
        buyNFT: async (sale) => {
          const data = await get(`nft/buy/${sale}/?user=` + address.value);
          if (!data.sale.currency) {
            const price = await web3.value.nft.methods
              .getPrice(address.value, data.sale.price)
              .call();
            return web3.value.nft.methods
              .tradeETH(
                data.sale.price,
                data.sale.nftId,
                data.sale.nonce,
                data.timestamp,
                data.signature
              )
              .send({ from: address.value, value: price });
          } else {
            return web3.value.nft.methods
              .trade(
                address.value,
                data.sale.currency,
                data.sale.price,
                data.sale.nftId,
                data.sale.nonce,
                data.timestamp,
                data.signature
              )
              .send({ from: address.value });
          }
        },
        buyFiat: async (sale, collection) => {
          let url = API_BASE + `fiat/buy/${sale}/`;
          if (collection)
            url += `?collection=${collection.collectionId}&ipfs=${collection.ipfs}`;
          var form = document.createElement("form");
          form.action = url;
          form.method = "post";
          document.body.appendChild(form);
          form.submit();
        },
        acceptTerms: async () => {
          const data = { tcAccepted: true };
          await addSignature(web3.value.web3, address.value, data);
          post(`user/`, data);
        },
        uploadFile: async (field, file, collection) => {
          const formData = new FormData();
          formData.append(field, file);
          const ts = new Date().getTime();
          const signature = await web3.value.web3.eth.personal.sign(
            ts.toString(),
            address.value,
            "798"
          );
          try {
            const res = await fetch(API_BASE + "upload/" + field, {
              method: "POST",
              headers: {
                "x-notable-ts": ts,
                "x-notable-sig": signature,
                "x-notable-collection": collection,
              },
              body: formData,
            });
            return await res.json();
          } catch (e) {
            console.log(e);
            return {};
          }
        },
        postMint: async (data) => {
          try {
            await addSignature(web3.value.web3, address.value, data);
            return await post("nft/mint", data);
          } catch (e) {
            return e;
          }
        },
        getMint: async (id, collection) => {
          // console.log(`Mining ${id} with collection ${collection}`);
          let data = {};
          if (collection) {
            const sig = {
              ipfs: collection.ipfs,
              collection: collection.collectionId.toString(),
              id: id.toString(),
            };
            await addSignature(web3.value.web3, address.value, sig);
            console.log("here");
            data = await get(
              `nft/mint/${id}/?collection=${collection.collectionId}&ipfs=${collection.ipfs}&signature=${sig.signature}&timestamp=${sig.timestamp}`
            );
          } else data = await get(`nft/mint/${id}/`);
          console.log("Data:", data);
          const provisioned = data.sale.provisionalData;
          try {
            return web3.value.nft.methods
              .mint(
                data.sale.currency,
                [
                  data.sale.price,
                  parseInt(new Date(data.sale.nft.start).getTime() / 1000),
                  parseInt(new Date(data.sale.nft.expire).getTime() / 1000),
                  data.sale.nonce,
                ],
                provisioned.creators,
                provisioned.dist,
                provisioned.locked,
                data.sale.nft.ipfs,
                data.signature
              )
              .send({
                from: address.value,
              });
          } catch (error) {
            console.log("Error: ", error);
            return;
          }
        },
        postCollection: async (data) => {
          try {
            await addSignature(web3.value.web3, address.value, data);
            return await post("nft/collection", data);
          } catch (e) {
            return e;
          }
        },
        otp: async (data) => {
          try {
            return await post("fiat/otp", data);
          } catch (e) {
            return e;
          }
        },
        verifyOtp: async (data) => {
          try {
            return await post("fiat/verify/otp", data);
          } catch (e) {
            return e;
          }
        },
        releaseMutex: async (id) => {
          try {
            return await post(`fiat/mutex/${id}`);
          } catch (e) {
            return e;
          }
        },
        getERC20Balance: async (address, token) => {
          try {
            const currency = currencyList.find(
              (c) => c.address.toLowerCase() == token.toLowerCase()
            );
            if (!currency)
              return { success: false, error: "Currency not found" };
            return await get(
              `fiat/${currency.name.toLowerCase()}?address=${address}`
            );
          } catch (e) {
            return e;
          }
        },
        getBusdBalance: async (address) => {
          // Fare richiesta al backend
          try {
            return await get(`fiat/busd?address=${address}`);
          } catch (e) {
            return e;
          }
          return "0.2";
        },
        getUsdtBalance: async (address) => {
          // Fare richiesta al backend
          try {
            return await get(`fiat/usdt?address=${address}`);
          } catch (e) {
            return e;
          }
          return "0.2";
        },
        getBNBBalance: async (address) => {
          return "0.1";
        },
        postTelegramSpace: async (username, nonce) => {
          try {
            return await post("spaces/telegram", {
              username,
              nonce,
            });
          } catch (e) {
            return e;
          }
        },
        getTelegramNonce: async (spaceId, nftId) => {
          try {
            const data = await get(
              `spaces/telegram?spaceId=${spaceId}&nftId=${nftId}`
            );
            if (data.success) return data.result[0].nonce;
            else return undefined;
          } catch (e) {
            return e;
          }
        },
        redeemNft: async (data) => {
          const redeem = {
            token: data,
            timestamp: new Date().getTime(),
          };
          await addSignature(web3.value.web3, address.value, redeem);
          const redeemData = await post("event/redeem", redeem);
          return web3.value.nft.methods
            .redeem(redeem.token, redeemData.timestamp, redeemData.signature)
            .send({
              from: address.value,
            });
        },
        useNft: async (tokenId) => {
          return web3.value.nft.methods.used(tokenId).send({
            from: address.value,
          });
        },
        requestKyc: async (tokenId, userMail) => {
          const kycData = {
            token: tokenId,
            email: userMail,
            timestamp: new Date().getTime(),
          };
          await addSignature(web3.value.web3, address.value, kycData);
          try {
            return await post("event/kyc", kycData);
          } catch (e) {
            return e;
          }
        },
        redeemRequest: async (tokenId, message, creator, creatorId) => {
          const redeem = {
            token: tokenId,
            message: message,
            creator: creator,
            creatorId: creatorId,
            timestamp: new Date().getTime(),
          };
          await addSignature(web3.value.web3, address.value, redeem);
          try {
            return await post("event/redeem/mail", redeem);
          } catch (e) {
            return e;
          }
        },
      },
    },
  };
});
