import { default as careerszuX6EXq2MdMeta } from "/Users/lollobene/Dev/Notable/eNFT-frontend/pages/careers.vue?macro=true";
import { default as collection_45creationulNjhMAAP7Meta } from "/Users/lollobene/Dev/Notable/eNFT-frontend/pages/collection-creation.vue?macro=true";
import { default as _91id_937EdUR8EnFVMeta } from "/Users/lollobene/Dev/Notable/eNFT-frontend/pages/collection/[id].vue?macro=true";
import { default as createPWSaV0JfnKMeta } from "/Users/lollobene/Dev/Notable/eNFT-frontend/pages/create.vue?macro=true";
import { default as _91id_93XgPob9CEB0Meta } from "/Users/lollobene/Dev/Notable/eNFT-frontend/pages/events/[id].vue?macro=true";
import { default as upcomingiKnLTwVN90Meta } from "/Users/lollobene/Dev/Notable/eNFT-frontend/pages/events/upcoming.vue?macro=true";
import { default as exploreYNEoE93sqLMeta } from "/Users/lollobene/Dev/Notable/eNFT-frontend/pages/explore.vue?macro=true";
import { default as familiesbiGnG086z0Meta } from "/Users/lollobene/Dev/Notable/eNFT-frontend/pages/families.vue?macro=true";
import { default as successHgCOZ5rwecMeta } from "/Users/lollobene/Dev/Notable/eNFT-frontend/pages/fiat/success.vue?macro=true";
import { default as indexnr8FJvJ73zMeta } from "/Users/lollobene/Dev/Notable/eNFT-frontend/pages/index.vue?macro=true";
import { default as mint7SS3IBqYUgMeta } from "/Users/lollobene/Dev/Notable/eNFT-frontend/pages/mint.vue?macro=true";
import { default as n_45mintTnl3cadvu9Meta } from "/Users/lollobene/Dev/Notable/eNFT-frontend/pages/n-mint.vue?macro=true";
import { default as _91id_93l8UlpY9GqnMeta } from "/Users/lollobene/Dev/Notable/eNFT-frontend/pages/nft/[id].vue?macro=true";
import { default as old_45stakingt5ulqM0UonMeta } from "/Users/lollobene/Dev/Notable/eNFT-frontend/pages/old-staking.vue?macro=true";
import { default as telegramCOteOHUYq1Meta } from "/Users/lollobene/Dev/Notable/eNFT-frontend/pages/spaces/telegram.vue?macro=true";
import { default as _91id_93lTd4v0U8bVMeta } from "/Users/lollobene/Dev/Notable/eNFT-frontend/pages/user/[id].vue?macro=true";
export default [
  {
    name: careerszuX6EXq2MdMeta?.name ?? "careers",
    path: careerszuX6EXq2MdMeta?.path ?? "/careers",
    meta: careerszuX6EXq2MdMeta || {},
    alias: careerszuX6EXq2MdMeta?.alias || [],
    redirect: careerszuX6EXq2MdMeta?.redirect,
    component: () => import("/Users/lollobene/Dev/Notable/eNFT-frontend/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: collection_45creationulNjhMAAP7Meta?.name ?? "collection-creation",
    path: collection_45creationulNjhMAAP7Meta?.path ?? "/collection-creation",
    meta: collection_45creationulNjhMAAP7Meta || {},
    alias: collection_45creationulNjhMAAP7Meta?.alias || [],
    redirect: collection_45creationulNjhMAAP7Meta?.redirect,
    component: () => import("/Users/lollobene/Dev/Notable/eNFT-frontend/pages/collection-creation.vue").then(m => m.default || m)
  },
  {
    name: _91id_937EdUR8EnFVMeta?.name ?? "collection-id",
    path: _91id_937EdUR8EnFVMeta?.path ?? "/collection/:id()",
    meta: _91id_937EdUR8EnFVMeta || {},
    alias: _91id_937EdUR8EnFVMeta?.alias || [],
    redirect: _91id_937EdUR8EnFVMeta?.redirect,
    component: () => import("/Users/lollobene/Dev/Notable/eNFT-frontend/pages/collection/[id].vue").then(m => m.default || m)
  },
  {
    name: createPWSaV0JfnKMeta?.name ?? "create",
    path: createPWSaV0JfnKMeta?.path ?? "/create",
    meta: createPWSaV0JfnKMeta || {},
    alias: createPWSaV0JfnKMeta?.alias || [],
    redirect: createPWSaV0JfnKMeta?.redirect,
    component: () => import("/Users/lollobene/Dev/Notable/eNFT-frontend/pages/create.vue").then(m => m.default || m)
  },
  {
    name: _91id_93XgPob9CEB0Meta?.name ?? "events-id",
    path: _91id_93XgPob9CEB0Meta?.path ?? "/events/:id()",
    meta: _91id_93XgPob9CEB0Meta || {},
    alias: _91id_93XgPob9CEB0Meta?.alias || [],
    redirect: _91id_93XgPob9CEB0Meta?.redirect,
    component: () => import("/Users/lollobene/Dev/Notable/eNFT-frontend/pages/events/[id].vue").then(m => m.default || m)
  },
  {
    name: upcomingiKnLTwVN90Meta?.name ?? "events-upcoming",
    path: upcomingiKnLTwVN90Meta?.path ?? "/events/upcoming",
    meta: upcomingiKnLTwVN90Meta || {},
    alias: upcomingiKnLTwVN90Meta?.alias || [],
    redirect: upcomingiKnLTwVN90Meta?.redirect,
    component: () => import("/Users/lollobene/Dev/Notable/eNFT-frontend/pages/events/upcoming.vue").then(m => m.default || m)
  },
  {
    name: exploreYNEoE93sqLMeta?.name ?? "explore",
    path: exploreYNEoE93sqLMeta?.path ?? "/explore",
    meta: exploreYNEoE93sqLMeta || {},
    alias: exploreYNEoE93sqLMeta?.alias || [],
    redirect: exploreYNEoE93sqLMeta?.redirect,
    component: () => import("/Users/lollobene/Dev/Notable/eNFT-frontend/pages/explore.vue").then(m => m.default || m)
  },
  {
    name: familiesbiGnG086z0Meta?.name ?? "families",
    path: familiesbiGnG086z0Meta?.path ?? "/families",
    meta: familiesbiGnG086z0Meta || {},
    alias: familiesbiGnG086z0Meta?.alias || [],
    redirect: familiesbiGnG086z0Meta?.redirect,
    component: () => import("/Users/lollobene/Dev/Notable/eNFT-frontend/pages/families.vue").then(m => m.default || m)
  },
  {
    name: successHgCOZ5rwecMeta?.name ?? "fiat-success",
    path: successHgCOZ5rwecMeta?.path ?? "/fiat/success",
    meta: successHgCOZ5rwecMeta || {},
    alias: successHgCOZ5rwecMeta?.alias || [],
    redirect: successHgCOZ5rwecMeta?.redirect,
    component: () => import("/Users/lollobene/Dev/Notable/eNFT-frontend/pages/fiat/success.vue").then(m => m.default || m)
  },
  {
    name: indexnr8FJvJ73zMeta?.name ?? "index",
    path: indexnr8FJvJ73zMeta?.path ?? "/",
    meta: indexnr8FJvJ73zMeta || {},
    alias: indexnr8FJvJ73zMeta?.alias || [],
    redirect: indexnr8FJvJ73zMeta?.redirect,
    component: () => import("/Users/lollobene/Dev/Notable/eNFT-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: mint7SS3IBqYUgMeta?.name ?? "mint",
    path: mint7SS3IBqYUgMeta?.path ?? "/mint",
    meta: mint7SS3IBqYUgMeta || {},
    alias: mint7SS3IBqYUgMeta?.alias || [],
    redirect: mint7SS3IBqYUgMeta?.redirect,
    component: () => import("/Users/lollobene/Dev/Notable/eNFT-frontend/pages/mint.vue").then(m => m.default || m)
  },
  {
    name: n_45mintTnl3cadvu9Meta?.name ?? "n-mint",
    path: n_45mintTnl3cadvu9Meta?.path ?? "/n-mint",
    meta: n_45mintTnl3cadvu9Meta || {},
    alias: n_45mintTnl3cadvu9Meta?.alias || [],
    redirect: n_45mintTnl3cadvu9Meta?.redirect,
    component: () => import("/Users/lollobene/Dev/Notable/eNFT-frontend/pages/n-mint.vue").then(m => m.default || m)
  },
  {
    name: _91id_93l8UlpY9GqnMeta?.name ?? "nft-id",
    path: _91id_93l8UlpY9GqnMeta?.path ?? "/nft/:id()",
    meta: _91id_93l8UlpY9GqnMeta || {},
    alias: _91id_93l8UlpY9GqnMeta?.alias || [],
    redirect: _91id_93l8UlpY9GqnMeta?.redirect,
    component: () => import("/Users/lollobene/Dev/Notable/eNFT-frontend/pages/nft/[id].vue").then(m => m.default || m)
  },
  {
    name: old_45stakingt5ulqM0UonMeta?.name ?? "old-staking",
    path: old_45stakingt5ulqM0UonMeta?.path ?? "/old-staking",
    meta: old_45stakingt5ulqM0UonMeta || {},
    alias: old_45stakingt5ulqM0UonMeta?.alias || [],
    redirect: old_45stakingt5ulqM0UonMeta?.redirect,
    component: () => import("/Users/lollobene/Dev/Notable/eNFT-frontend/pages/old-staking.vue").then(m => m.default || m)
  },
  {
    name: telegramCOteOHUYq1Meta?.name ?? "spaces-telegram",
    path: telegramCOteOHUYq1Meta?.path ?? "/spaces/telegram",
    meta: telegramCOteOHUYq1Meta || {},
    alias: telegramCOteOHUYq1Meta?.alias || [],
    redirect: telegramCOteOHUYq1Meta?.redirect,
    component: () => import("/Users/lollobene/Dev/Notable/eNFT-frontend/pages/spaces/telegram.vue").then(m => m.default || m)
  },
  {
    name: _91id_93lTd4v0U8bVMeta?.name ?? "user-id",
    path: _91id_93lTd4v0U8bVMeta?.path ?? "/user/:id()",
    meta: _91id_93lTd4v0U8bVMeta || {},
    alias: _91id_93lTd4v0U8bVMeta?.alias || [],
    redirect: _91id_93lTd4v0U8bVMeta?.redirect,
    component: () => import("/Users/lollobene/Dev/Notable/eNFT-frontend/pages/user/[id].vue").then(m => m.default || m)
  }
]