import io from "socket.io-client";

export default defineNuxtPlugin((nuxtApp) => {
  let API_BASE =
    nuxtApp.$config.public.env == "development"
      ? "http://localhost:8080/"
      : nuxtApp.$config.public.platform == "test"
      ? "https://test.notablenft.app"
      : "https://platform.notablenft.app";
  const socket = io(API_BASE, {
    path: "/socket.io",
  });

  return {
    provide: {
      io: socket,
    },
  };
});
