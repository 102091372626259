import { NBLStaking, NBL, eNFT, BUSD } from "~/data/abis";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers";
import { bsc, bscTestnet } from "viem/chains";

const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Notable",
  url: "https://notablenft.app", // origin must match your domain & subdomain.
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const bscMainnet = {
  chainId: 56,
  name: "Binance Smart Chain",
  currency: "BNB",
  explorerUrl: "https://bscscan.com",
  rpcUrl: "https://bsc-dataseed.binance.org",
};

const testnet = {
  chainId: bscTestnet.id,
  name: "Binance Smart Chain Testnet",
  currency: bscTestnet.nativeCurrency.symbol,
  explorerUrl: bscTestnet.blockExplorers.default.url,
  rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545",
};

const ethersConfig = defaultConfig({
  metadata,
});

function handleLogin(nuxtApp, address, web3, tcModal, isCreator, userKYC) {
  nuxtApp.$api.updateLogin(address);
  nuxtApp.$api.getUser(address).then((user) => {
    if (!user.tcAccepted) {
      // TODO add condition to check if T&C accepted version is up to date
      // TODO Show Terms And Conditions Modal
      tcModal.value = true;
      // set T&C modal global variable to TRUE
    }
    if (user.creatorKYC) isCreator.value = true;
    else isCreator.value = false;
    if (user.userKYC) userKYC.value = true;
    else userKYC.value = false;
  });
}

async function handleConnection(
  data,
  connected,
  isCreator,
  tcModal,
  userKYC,
  nuxtApp,
  address
) {
  const provider = data.modal.getWalletProvider();
  if (!provider) {
    console.log("Provider not found");
    return;
  }
  if (data.web3) {
    connected.value = true;
    return;
  }
  data.web3 = new data.Web3(provider);
  data.web3.eth.getAccounts().then((accounts) => {
    console.log(accounts);
  });
  const gasPrice = await data.web3.eth.getGasPrice();
  data.stake = new data.web3.eth.Contract(
    NBLStaking,
    nuxtApp.$config.public.staking,
    { gasPrice }
  );
  data.nbl = new data.web3.eth.Contract(NBL, nuxtApp.$config.public.nbl, {
    gasPrice,
  });
  data.nft = new data.web3.eth.Contract(eNFT, nuxtApp.$config.public.nft, {
    gasPrice,
  });
  data.busd = new data.web3.eth.Contract(BUSD, nuxtApp.$config.public.busd, {
    gasPrice,
  });
  connected.value = true;

  provider.on("accountsChanged", (accounts) => {
    if (accounts.length) {
      address.value = accounts[0];
      isCreator.value = false;
      tcModal.value = false;
      handleLogin(
        nuxtApp,
        address.value,
        data.web3,
        tcModal,
        isCreator,
        userKYC
      );
    } else connected.value = false;
  });
  provider.on("disconnect", () => {
    connected.value = false;
  });

  data.web3.eth.getAccounts().then((accounts) => {
    if (accounts.length) {
      address.value = accounts[0];
      handleLogin(
        nuxtApp,
        address.value,
        data.web3,
        tcModal,
        isCreator,
        userKYC
      );
    } else connected.value = false;
  });
}

export default defineNuxtPlugin((nuxtApp) => {
  const web3 = useWeb3();
  const connected = useConnected();
  const address = useAddress();
  const tcModal = useTCmodal();
  const isCreator = useCreator();
  const userKYC = useUserKYC();
  const config = useRuntimeConfig();
  const chains = config.public.network == "mainnet" ? [bscMainnet] : [testnet];
  const projectId = config.public.walletConnectProjectId;
  console.log(projectId);
  console.log(chains);
  const modal = createWeb3Modal({
    ethersConfig,
    chains,
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    enableOnramp: true, // Optional - false as default
  });
  web3.value.modal = modal;
  return {
    provide: {
      preconnect: async () => {
        const data = web3.value;
        if (data.modal.getIsConnected()) {
          console.log("modal connected");
          await handleConnection(
            data,
            connected,
            isCreator,
            tcModal,
            userKYC,
            nuxtApp,
            address
          );
        }
      },
      connect: async () => {
        const data = web3.value;
        if (connected.value) return;
        localStorage.removeItem("walletconnect");
        if (data.modal.getIsConnected()) {
          console.log("modal connected");
          await handleConnection(
            data,
            connected,
            isCreator,
            tcModal,
            userKYC,
            nuxtApp,
            address
          );
        } else {
          data.modal.open();
          data.modal.subscribeProvider(async (newState) => {
            await handleConnection(
              data,
              connected,
              isCreator,
              tcModal,
              userKYC,
              nuxtApp,
              address
            );
          });
        }
      },
      disconnect: () => {
        connected.value = false;
        web3.value.modal.disconnect();
      },
      async checkApproval(erc20, owner) {
        const token = new web3.value.web3.eth.Contract(NBL, erc20);
        return await token.methods
          .allowance(owner, nuxtApp.$config.public.nft)
          .call();
      },
      async checkBalance(erc20, owner) {
        const token = new web3.value.web3.eth.Contract(NBL, erc20);
        return await token.methods.balanceOf(owner).call();
      },
      async approveToken(erc20) {
        const gasPrice = await web3.value.web3.eth.getGasPrice();
        const token = new web3.value.web3.eth.Contract(BUSD, erc20, {
          gasPrice,
        });
        return token.methods
          .approve(
            nuxtApp.$config.public.nft,
            "115792089237316195423570985008687907853269984665640564039457584007913129639935"
          )
          .send({ from: address.value });
      },
    },
  };
});
